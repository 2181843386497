import React, { useContext } from "react";
import { AuthContext } from "../App";

const Logo = () => {
    const { theme } = useContext(AuthContext)
    return (
        <>
            {
                theme == "dark" ? <img src="/logo/logodark.svg" className="logodark img-fluid" alt="Vasundhara Infotech - App Reviews"/> :
                <img src="/logo/logo.svg" className="logo img-fluid" alt="Vasundhara Infotech - App Reviews"/>
            }
        </>
     );
};

export default Logo;