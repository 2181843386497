import React, { useContext } from "react";
import { AuthContext } from "../App";

const LogoMini = () => {
    const { theme } = useContext(AuthContext)
    return (
        <>
            {
                theme == "dark" ? <img src="/logo/logomini-dark.svg" className="logo-mini-dark img-fluid" alt="Vasundhara Infotech - App Reviews"/> 
                : <img src="/logo/logomini.svg" className="logo-mini img-fluid" alt="Vasundhara Infotech - App Reviews"/>
            }
        </>
     );
};

export default LogoMini;