import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from "react-bootstrap";
import { Link, useParams } from 'react-router-dom';
import Layout from '../../layout/Layout';
import { getAndroidReview } from './service';
import moment from 'moment-timezone';
const AppReviewsAdViews = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [androidReview, setAndroidReview] = useState(true)
  const { id } = useParams()

  /**get Application list */
  const getAndroidsReview = async () => {
    try {
      setIsLoading(true)
      const formData = new FormData()
      formData?.append("id", id)
      const result = await getAndroidReview(formData)
      if (result?.data?.status) {
        setAndroidReview(result?.data?.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    getAndroidsReview()
  }, [id])

  const convertTimeZone = (date) =>{
    const formateDate = moment(date).format()
    const result = moment(formateDate).tz('Asia/Kolkata').format("DD-MM-YYYY hh:mm A");
    return result
  }
  return (
    <Layout sidebar={true}>
      <div className="page-heading backbtn">
        <h3>
          <Link to="/appreviews-ad" className='btn btn-transparent btn-icon me-2'>
            <i className='bx bx-chevron-left' ></i>
          </Link>
          App Reviews AD View
        </h3>
      </div>
      <div className='page-content'>
        <Card className="radiustop-0">
          <Card.Body>
          {isLoading && <div className="loader table-loader"></div>}
            <Row>
              {/* <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>App Name</p><span>{androidReview?.}	</span>
                </div>
              </Col> */}
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Package Name</p><span>{androidReview?.package_name ? androidReview?.package_name : "-"}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Version Code</p><span>{androidReview?.version_code ? androidReview?.version_code : "-"}</span>
                </div>
              </Col>
              <Col md={12}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Review</p><span>{androidReview?.review ? androidReview?.review : "-"}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Version Name</p><span>{androidReview?.version_name ? androidReview?.version_name : "-"}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Ratings</p><span>{androidReview?.ratings ? androidReview?.ratings : "-"}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Email / Number</p><span>{androidReview?.contact_information ? androidReview?.contact_information : "-"}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Created At</p><span>{androidReview?.created_at ? convertTimeZone(androidReview?.created_at)  : "-"}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Updated At</p><span>{androidReview?.updated_at ? convertTimeZone(androidReview?.updated_at) : "-"}</span>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  )
}

export default AppReviewsAdViews