import { API } from "../../App";
import Cookies from "js-cookie";


export const getIosReview = async (payload) => {
      const token = Cookies.get("fmljwt")
      try {
          const result = await API.post("/review-list-ios", payload, { headers: { authorization: `${token}` } })
          return result
      } catch (error) {
          console.log(error)
      }
  
  };
export const iosAppName = async (payload) => {
      const token = Cookies.get("fmljwt")
      try {
          const result = await API.post("/review-dropdown-ios", {}, { headers: { authorization: `${token}` } })
          return result
      } catch (error) {
          console.log(error)
      }
  
  };
export const iosVersionCode = async (payload) => {
      const token = Cookies.get("fmljwt")
      try {
          const result = await API.post("/ios-vesion-codes", payload, { headers: { authorization: `${token}` } })
          return result
      } catch (error) {
          console.log(error)
      }
  
  };
export const deleteReview = async (payload) => {
      const token = Cookies.get("fmljwt")
      try {
          const result = await API.post("/delete-review-IOS", payload, { headers: { authorization: `${token}` } })
          return result
      } catch (error) {
          console.log(error)
      }
  
  };
