import moment from 'moment';
import Pagination from "rc-pagination";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { SelectPicker } from "rsuite";
import Swal from "sweetalert2";
import Layout from '../../layout/Layout';
import { androidAppName, androidVersionCode, deleteReview, getAndroidReview } from "./service";

const limits = ['25', "50", '100'].map(
  item => ({ label: item, value: item })
);
const reviewType = [{ label: "Subscription Review", value: 0 }, { label: "Review", value: 1 }];
const AppReviewsAnd = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [androidReviewList, setAndroidReviewList] = useState(true)
  const [limit, setLimit] = useState("25");
  const [current, setCurrent] = useState(1);
  const [selectedReview, setSelectedReview] = useState([]);
  const [dropdown, setDropdown] = useState({ appName: [], version: [] });
  const [selectFilter, setSelectFilter] = useState({
    appName: "",
    version: "",
    isReview: 0,
  })
  const [dateRanges, setdateRanges] = useState({
    start: moment().subtract(6, 'days'),
    end: moment(),
  });
  const label = dateRanges?.start?.format('DD/MM/YYYY') + ' - ' + dateRanges?.end?.format('DD/MM/YYYY');
  const handleCallback = (start, end) => {
    setdateRanges({ start, end });
    setCurrent(1);
  };

  /**get Application name  */
  const getAppName = async () => {
    try {
      const response = await androidAppName()
      if (response?.data?.status) {
        const allOption = [{ value: "", label: "All Applications" }];
        const transformedData = response?.data?.data?.length > 0 ? response?.data?.data?.map(item => ({
          value: item.packageName,
          label: item.applicationName
        })) : [];
        setDropdown(prevState => ({
          ...prevState,
          appName: [...allOption, ...transformedData],
        }))
      };

    }
    catch (error) {
      console.log(error)
    } finally {
      // setIsLoading(false)
    }
  };
  /**get version code  */
  const getVersionCode = async (packageName) => {
    try {
      const formData = new FormData()
      formData?.append("package_name", packageName)
      const response = await androidVersionCode(formData)
      if (response?.data?.status) {
        const allOption = [{ value: "", label: "All Version Code" }];
        const transformedData = response?.data?.data?.length > 0 ? response?.data?.data?.map(item => ({
          value: item.version_codes,
          label: item.version_codes
        })) : [];
        setDropdown(prevState => ({
          ...prevState,
          version: [...allOption, ...transformedData]
        }))
      }

    }
    catch (error) {
      console.log(error)
    } finally {
      // setIsLoading(false)
    }
  };
  /**get Application list */
  const fetchAndroidReview = async () => {
    try {
      setIsLoading(true)
      const formData = new FormData()
      formData?.append("limit", limit)
      formData?.append("page", current)
      selectFilter?.appName && formData?.append("package_name", selectFilter?.appName)
      selectFilter?.version && formData?.append("version_code", selectFilter?.version)
      formData?.append("isReview", selectFilter?.isReview)
      formData.append("startDate", dateRanges?.start.format('YYYY-MM-DD'))
      formData.append("endDate", dateRanges?.end.format('YYYY-MM-DD'))
      const result = await getAndroidReview(formData)
      if (result?.data?.status) {
        setAndroidReviewList(result?.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  };


  const onChange = page => {
    setCurrent(page);
  };

  const handleLimitChange = (e) => {
    setCurrent(1);
    setLimit(e)
  }

  let count = 10
  let swalCountdownInterval
  const handleDelete = async (key, id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: '../../icon/alert.svg',
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        // didOpen: () => {
        //   $(".swal2-confirm").attr('disabled', true);
        //   swalCountdownInterval = setInterval(function () {
        //     count--
        //     if (count < 1) {
        //       $(".counter").text(`OK`)
        //       $(".swal2-confirm").attr('disabled', false);
        //       clearInterval(swalCountdownInterval)
        //     } else {
        //       $(".counter").text(`OK (${count})`)
        //     }
        //   }, 1000);
        // }
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true)
          const formData = new FormData
          formData?.append(key, id)
          const deletedata = await deleteReview(formData)
          if (deletedata?.data?.status) {
            toast.success(deletedata?.data?.message)
            setSelectedReview([])
          }
          fetchAndroidReview();
          setCurrent(1);
        } else {
          // count = 10
          // clearInterval(swalCountdownInterval)
        }
      });
  };
  const handleAllSelect = () => {
    if (selectedReview?.length != androidReviewList?.data?.length) {
      setSelectedReview(androidReviewList.data.map((item) => item.id));
    } else {
      setSelectedReview([]);
    }
  };

  const handleCheck = (id) => {
    if (selectedReview?.includes(id)) {
      setSelectedReview(selectedReview.filter((item) => item !== id));
    } else {
      setSelectedReview([...selectedReview, id]);
    }
  };


  useEffect(() => {
    getAppName()
  }, [])

  useEffect(() => {
    setSelectedReview([])
  }, [current])

  useEffect(() => {
    fetchAndroidReview()
  }, [current, limit, selectFilter, dateRanges])


  /*** convert timezone function */
  const convertTimeZone = (date) => {
    const formateDate = moment(date).format()
    const result = moment(formateDate).tz('Asia/Kolkata').format("DD-MM-YYYY hh:mm A");
    return result
  }
  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1">App Reviews Android</h3>
        <div className="page-heading-right">
          {selectFilter?.isReview == 1 && selectedReview?.length > 0 && <Button variant="danger ms-3 my-1 btn-icon-text" onClick={() => handleDelete("id_list", selectedReview?.toString())} type="button"><i className="bx bx-trash-alt"></i>Delete</Button>}

          <SelectPicker
            cleanable={false}
            data={reviewType}
            value={selectFilter?.isReview}
            onChange={(e) => {
              setCurrent(1);
              setSelectFilter(prevState => ({
                ...prevState,
                isReview: e,
              }));
            }}
            searchable={false}
            className="wv-200 my-1 ms-3"
            placeholder="Select Record"
          />
          <SelectPicker
            data={dropdown?.appName}
            onChange={(e) => {
              setCurrent(1);
              setSelectFilter(prevState => ({
                ...prevState,
                appName: e,
                version: ""
              }));
              getVersionCode(e);
              setDropdown(prevState => ({
                ...prevState,
                version: []
              }))
            }
            }
            cleanable={false}
            className="wv-250 my-1 ms-3"
            placeholder="Select App Name"
            placement="bottomEnd"
          />
          <SelectPicker
            data={dropdown?.version}
            onChange={(e) => {
              setCurrent(1);
              setSelectFilter(prevState => ({
                ...prevState,
                version: e
              }))
            }}
            cleanable={false}
            className="wv-150 my-1 ms-3"
            placeholder="Select Version"
            placement="bottomEnd"
          />
          <DateRangePicker initialSettings={{
            startDate: dateRanges?.start.toDate(),
            endDate: dateRanges?.end.toDate(),
            ranges: {
              Today: [moment().toDate(), moment().toDate()],
              Yesterday: [
                moment().subtract(1, 'days').toDate(),
                moment().subtract(1, 'days').toDate(),
              ],
              'Last 7 Days': [
                moment().subtract(6, 'days').toDate(),
                moment().toDate(),
              ],
              'Last 30 Days': [
                moment().subtract(29, 'days').toDate(),
                moment().toDate(),
              ],
              'This Month': [
                moment().startOf('month').toDate(),
                moment().endOf('month').toDate(),
              ],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month').toDate(),
                moment().subtract(1, 'month').endOf('month').toDate(),
              ],
            },
          }}
            onCallback={handleCallback}>
            <input type="text" name="date" value={label} placeholder="Select Date" className="form-control wv-225 my-1 ms-3 " />
          </DateRangePicker>
          <SelectPicker
            cleanable={false}
            data={limits}
            value={limit}
            onChange={handleLimitChange}
            searchable={false}
            className="wv-100 my-1 ms-3"
            placeholder="Select Record"
          />
        </div>
      </div>
      <div className="page-content">
        <Card className="radiustop-0">
          <Card.Body>
            {isLoading && <div className="loader table-loader"></div>}
            <Table className="table-bg-primary table-delete-checkbox" striped bordered responsive>
              <thead>
                <tr>
                  {selectFilter?.isReview == 1 && <th width="5%" className="text-center">
                    <Form.Check type="checkbox" checked={androidReviewList?.data?.length == selectedReview?.length} onClick={handleAllSelect} />
                  </th>}
                  <th width="5%" className="text-center">No</th>
                  {selectFilter?.isReview == 1
                    ? <th width="45%">Review</th>
                    : <th width="45%" className="text-center">Subscription Review</th>}
                  {selectFilter?.isReview == 1
                    ? <th width="35%" className="text-center">Use Of App</th>
                    : <th width="35%" className="text-center">Count</th>}
                  {selectFilter?.isReview == 1 && <th width="10%" className='text-center'>Action</th>}
                </tr>
              </thead>
              <tbody>
                {androidReviewList?.data?.length > 0 ? androidReviewList?.data?.map((item, index) => {
                  return (<tr key={index}>
                    {selectFilter?.isReview == 1 && <td width="5%" className="text-center">
                      <Form.Check type="checkbox" checked={selectedReview?.includes(item?.id)} onClick={() => handleCheck(item?.id)} />
                    </td>}
                    <td className='text-center'>{(current === 1) ? index + 1 : current * limit + index + 1 - limit}</td>
                    {selectFilter?.isReview == 1
                      ? <td><p className='mb-0 break-word'>{item?.review ? item?.review : "-"}</p></td>
                      : <td className="text-center">{item?.subscription_review ? item?.subscription_review : "-"}</td>}
                    {/* <td className="text-center">{item?.version_code ? item?.version_code : "-"}</td> */}
                    {selectFilter?.isReview == 1
                      ? <td className="text-center"><p className='mb-0 break-word'>{item?.use_of_app ? item?.use_of_app : "-"}</p></td>
                      : <td className="text-center">{item?.count ? item?.count : 0}</td>}
                    {selectFilter?.isReview == 1 && <td>
                      <div className='d-flex align-items-center justify-content-center'>
                        <Link to={`/appreviews-ad/view/${item?.id}`} className="btn btn-icon btn-transparent btn-primary">
                          <i className='bx bx-show'></i>
                        </Link>
                        <span className='border-start py-2 mx-2'></span>
                        <Button variant="danger" size="sm" onClick={() => handleDelete("id", item?.id)} className="btn-icon btn-transparent">
                          <i className='bx bx-trash' ></i>
                        </Button>
                      </div>
                    </td>}
                  </tr>)
                }) : <tr>
                  <td colSpan="100%" className="p-0">
                    <div className='no-found'>
                      <img src="../../not-found/app.svg" />
                      <p>No Found App Reviews</p>
                    </div>
                  </td>
                </tr>}
              </tbody>
            </Table>
            {androidReviewList?.total > limit && <div className="pagination-custom">
              <Pagination
                className="pagination-data"
                onChange={onChange}
                current={current}
                total={androidReviewList?.total}
                pageSize={limit}
                showSizeChanger={false}
                showTitle={false}
              />
            </div>}
          </Card.Body>
        </Card>
      </div>
    </Layout>
  )
}

export default AppReviewsAnd