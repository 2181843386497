import React, { useEffect, useState } from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../layout/Layout';
import Pagination from "rc-pagination";
import { SelectPicker } from "rsuite";
import { applicationAdd, deleteApplication, getApplicationList } from "./service";
import Swal from "sweetalert2";
import $ from "jquery";
import { toast } from "react-toastify";

const limits = ['25', "50", '100'].map(
  item => ({ label: item, value: item })
);
const device = [{ label: 'All', value: null }, { label: 'Ios', value: 1 }, { label: 'Android', value: 0 }]
const Application = () => {

  const [isLoading, setIsLoading] = useState(true)
  const [applicationList, setApplicationList] = useState(true)
  const [selectedState, setSelectedState] = useState("");
  const [limit, setLimit] = useState("25");
  const [current, setCurrent] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [searchAppName, setSearchAppName] = useState('')
  const [selectDeviceType, setSelectDeviceType] = useState(null)
  const navigate = useNavigate()

  /**get Application list */
  const getAppList = async () => {
    try {
      setIsLoading(true)
      const formData = new FormData()
      formData?.append("limit", limit)
      formData?.append("page", current)
      formData?.append("searchTerm", searchValue)
      selectDeviceType != null && formData?.append("type", selectDeviceType)
      const result = await getApplicationList(formData)
      if (result?.data?.status) {
        setApplicationList(result?.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  };
  /**togglechange */
  const toggleChange = async (type, id) => {
    try {
      const formData = new FormData()
      formData?.append("type", type)
      formData?.append("id", id)

      const result = await applicationAdd(formData)
      if (result?.data?.status) {
        getAppList()
        toast?.success("Is Ios status updated")
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  };

  const onChange = page => {
    setCurrent(page);
  };

  const handleLimitChange = (e) => {
    setCurrent(1);
    setLimit(e)
  }

  const handleSearch = () => {
    setSearchValue(searchAppName)
    setCurrent(1);
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default form submission behavior
      handleSearch()
    }
  };

  /**delete service*/
  let count = 10
  let swalCountdownInterval
  const handleDelete = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: '../../icon/alert.svg',
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        // didOpen: () => {
        //   $(".swal2-confirm").attr('disabled', true);
        //   swalCountdownInterval = setInterval(function () {
        //     count--
        //     if (count < 1) {
        //       $(".counter").text(`OK`)
        //       $(".swal2-confirm").attr('disabled', false);
        //       clearInterval(swalCountdownInterval)
        //     } else {
        //       $(".counter").text(`OK (${count})`)
        //     }
        //   }, 1000);
        // }
      })
      .then(async (result) => {
        try {

          if (result.isConfirmed) {
            const formData = new FormData()
            formData?.append("id", id)
            const deletedata = await deleteApplication(formData)
            navigate("/application")
            getAppList()

          // } else {
          //   count = 10
          //   clearInterval(swalCountdownInterval)
          }
        } catch (error) {
          console.log('error', error)
        }
      });
  };


  useEffect(() => {
    getAppList()
  }, [current, limit, searchValue,selectDeviceType])
  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1">Application</h3>
        <div className="page-heading-right">
          <div className="inputgroup ms-3 my-1">
            <Form.Control
              type="text"
              name="reg_no"
              placeholder=" Serach Application Name"
              className="wv-300"
              onChange={(e) => { e.target?.value == "" && setSearchValue(""); setSearchAppName(e.target.value) }}
              onKeyPress={handleKeyPress}
            />
            <i className="bx bx-search" onClick={handleSearch} />
          </div>
          <SelectPicker
            cleanable={false}
            data={device}
            value={selectDeviceType}
            onChange={(e) => setSelectDeviceType(e)}
            searchable={false}
            className="wv-150 my-1 ms-3"
            placeholder="Select Device type"
          />
          <SelectPicker
            cleanable={false}
            data={limits}
            value={limit}
            onChange={handleLimitChange}
            searchable={false}
            className="wv-100 my-1 ms-3"
            placeholder="Select Record"
          />
          <Link to="/application/add" className="btn btn-primary ms-3 my-1">Add New</Link>
        </div>
      </div>
      <div className="page-content">
        <Card className="radiustop-0">
          <Card.Body>
            {isLoading && <div className="loader table-loader"></div>}
            <Table className="table-bg-primary" striped bordered responsive>
              <thead>
                <tr>
                  <th width="5%" className="text-center">No</th>
                  <th width="35%">App Name</th>
                  <th width="40%">Package Name</th>
                  {/* <th width="10%">Is IOS</th> */}
                  <th width="10%" className='text-center'>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  isLoading == false && applicationList?.data?.length > 0 ? applicationList?.data?.map((item, index) => {
                    return (<tr key={index}>
                      <td className='text-center'>{(current === 1) ? index + 1 : current * limit + index + 1 - limit}</td>
                      <td>{item?.applicationName}</td>
                      <td>{item?.packageName}</td>
                      {/* <td> <Form.Check
                        type="switch"
                        onChange={(e) => toggleChange(e.target.checked ? 1 : 0, item?.id)}
                        name="isIos"
                        checked={item?.type === 1 ? true : false}
                      /></td> */}

                      <td>
                        <div className='d-flex align-items-center justify-content-center'>
                          <Link to={`/application/view/${item?.id}`} className="btn btn-icon btn-transparent btn-primary">
                            <i className='bx bx-show'></i>
                          </Link>
                          <span className='border-start py-2 mx-2'></span>
                          <Button variant="danger" size="sm" onClick={() => handleDelete(item?.id)} className="btn-icon btn-transparent">
                            <i className='bx bx-trash' ></i>
                          </Button>
                        </div>
                      </td>
                    </tr>)
                  })
                    : <tr>
                      <td colSpan="100%" className="p-0">
                        <div className='no-found'>
                          <img src="../../not-found/app.svg" />
                          <p>No Found Application</p>
                        </div>
                      </td>
                    </tr>
                }
              </tbody>
            </Table>
            {applicationList?.total > limit && <div className="pagination-custom">
              <Pagination
                className="pagination-data"
                onChange={onChange}
                current={current}
                total={applicationList?.total}
                pageSize={limit}
                showSizeChanger={false}
                showTitle={false}
              />
            </div>}
          </Card.Body>
        </Card>
      </div>
    </Layout>
  )
}

export default Application