import React from 'react';
import { Card } from 'react-bootstrap';
import Layout from '../layout/Layout';

const Home = () => {
    return (
        <Layout sidebar={true}>
            <Card>
                <Card.Body>
                    <h4 className='mb-0 fw-bold text-center'>Welcome to <span className='text-primary'>App Reviews</span></h4>
                </Card.Body>
            </Card>
        </Layout>
    )
}

export default Home