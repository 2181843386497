import { API } from "../../App";
import Cookies from "js-cookie";


export const getApplicationList = async (payload) => {
      const token = Cookies.get("fmljwt")
      try {
          const result = await API.post("/application-list", payload, { headers: { authorization: `${token}` } })
          return result
      } catch (error) {
          console.log(error)
      }
  
  };
export const applicationAdd = async (payload) => {
      const token = Cookies.get("fmljwt")
      try {
          const result = await API.post("/add-application", payload, { headers: { authorization: `${token}` } })
          return result
      } catch (error) {
          console.log(error)
      }
  
  };
export const deleteApplication = async (payload) => {
      const token = Cookies.get("fmljwt")
      try {
          const result = await API.post("/application-delete", payload, { headers: { authorization: `${token}` } })
          return result
      } catch (error) {
          console.log(error)
      }
  
  };