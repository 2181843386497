import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SelectPicker } from 'rsuite';
import Layout from '../../layout/Layout';
import { deleteApplication, getApplicationList } from './service';

import Swal from "sweetalert2";
import $ from "jquery";

const ApplicationView = () => {

  const [isLoading, setIsLoading] = useState(true)
  const [application, setApplication] = useState([])
  const { id } = useParams()
  const navigate = useNavigate()



  /**get Application */
  const getAppList = async () => {
    try {
      setIsLoading(true)
      const formData = new FormData()
      formData?.append("id", id)
      const result = await getApplicationList(formData)
      if (result?.data?.status) {
        setApplication(result?.data?.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  };

  /**delete service*/
  let count = 10
  let swalCountdownInterval
  const handleDelete = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: '../../icon/alert.svg',
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK (10)",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        didOpen: () => {
          $(".swal2-confirm").attr('disabled', true);
          swalCountdownInterval = setInterval(function () {
            count--
            if (count < 1) {
              $(".counter").text(`OK`)
              $(".swal2-confirm").attr('disabled', false);
              clearInterval(swalCountdownInterval)
            } else {
              $(".counter").text(`OK (${count})`)
            }
          }, 1000);
        }
      })
      .then(async (result) => {
        try {

          if (result.isConfirmed) {
            const formData = new FormData()
            formData?.append("id", id)
            const deletedata = await deleteApplication(formData)
            navigate("/application")
          } else {
            count = 10
            clearInterval(swalCountdownInterval)
          }
        } catch (error) {
          console.log('error', error)
        }
      });
  };


  useEffect(() => {
    getAppList()
  }, [id])
  return (
    <Layout sidebar={true}>
      <div className="page-heading backbtn">
        <h3>
          <Link to="/application" className='btn btn-transparent btn-icon me-2'>
            <i className='bx bx-chevron-left' ></i>
          </Link>
          Application View
        </h3>
        <div className="page-heading-right">
          {/* <SelectPicker
            cleanable={false}
            className="wv-200 my-1 ms-3"
            placeholder="Select App Name"
            placement="bottomEnd"
          /> */}

          <Link to={`/application/edit/${id}`} className='btn btn-primary ms-3 my-1'> Edit</Link>

          <Button variant="danger ms-3 my-1 btn-icon-lg" onClick={()=>handleDelete(id)} type="button"><i className="bx bx-trash-alt"></i></Button>
        </div>
      </div>
      <div className='page-content'>
        <Card className="radiustop-0">
          <Card.Body>
          {isLoading && <div className="loader table-loader"></div>}

            <Row>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>App Name</p><span>{application?.applicationName}</span>
                </div>
              </Col>
              <Col md={6}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Package Name</p><span>{application?.packageName}</span>
                </div>
              </Col>
              <Col md={2}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>is iOS</p><span>{application?.type == 1 ? "On" : "Off"}</span>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  )
}

export default ApplicationView