import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from '../../layout/Layout';
import { applicationAdd, getApplicationList } from "./service";
import { toast } from "react-toastify";

const ApplicationAdd = () => {

  const [isLoading, setIsLoading] = useState(true)
  const [application, setApplication] = useState({ applicationName: "", packageName: "", type: 0 })
  const [validated, setValidated] = useState(false);
  const { id } = useParams()
  const navigate = useNavigate()



  /**get Application */
  const getAppList = async () => {
    try {
      setIsLoading(true)
      const formData = new FormData()
      formData?.append("id", id)
      const result = await getApplicationList(formData)
      if (result?.data?.status) {
        setApplication(result?.data?.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  };


  /** handle Change input values*/
  const handleChange = (e, field) => {
    setApplication((prev) => ({
      ...prev,
      [field]: e,
    }));
  }


  /**Save the Application data  */
  const handleSubmit = async (event) => {
    setValidated(true)
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setValidated(true);
      try {
        const formData = new FormData()
        id && formData?.append("id", id)
        formData?.append("applicationName", application?.applicationName)
        formData?.append("packageName", application?.packageName)
        formData?.append("type", application?.type)

        const response = await applicationAdd(formData)

        if (response?.status) {
          if (id) {
            toast.success(response?.data?.message);
            navigate(`/application/view/${id}`)
          } else {
            toast.success(response?.data?.message);
            navigate(`/application`)
          }
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
  };

  useEffect(() => {
    id && getAppList()
  }, [id])
  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Application Add</h3>
        <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
          <Breadcrumb.Item >
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item >
            <Link to="/application">Application</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Application Add</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="page-content">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Card className="mb-4 radiustop-0">
            <Card.Body>
              <Row>
                <Col md={4}>
                  <Form.Label>App Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="applicationName"
                    className="my-2"
                    value={application?.applicationName}
                    onChange={(e) => handleChange(e?.target?.value, "applicationName")}
                    required
                  />
                </Col>
                <Col md={6}>
                  <Form.Label>Package Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="packageName"
                    className="my-2"
                    value={application?.packageName}
                    onChange={(e) => handleChange(e?.target?.value, "packageName")}
                    required
                  />
                </Col>
                <Col md={2}>
                  <Form.Label>is iOS</Form.Label>
                  <Form.Check
                    type="switch"
                    className="my-2"
                    checked={application?.type == 1}
                    onChange={(e) => handleChange(e?.target?.checked ? 1 : 0, "type")} />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="text-end">
              <Button variant="primary" type="submit" className="me-3">Save</Button>
              <Link to='/application'>
                <Button variant="secondary">Cancel</Button>
              </Link>
            </Card.Footer>
          </Card>
        </Form>
      </div>
    </Layout>
  )
}

export default ApplicationAdd