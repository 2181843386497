import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from "react-bootstrap";
import { Link, useParams } from 'react-router-dom';
import Layout from '../../layout/Layout';
import { getIosReview } from './service';
import moment from 'moment-timezone';

const AppReviewsIOSViews = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [iosReview, setIosReview] = useState(true)
  const { id } = useParams()

  /**get Application list */
  const getIossReview = async () => {
    try {
      setIsLoading(true)
      const formData = new FormData()
      formData?.append("id", id)
      const result = await getIosReview(formData)
      if (result?.data?.status) {
        setIosReview(result?.data?.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    getIossReview()
  }, [id])
  
  const convertTimeZone = (date) =>{
    const formateDate = moment(date).format()
    const result = moment(formateDate).tz('Asia/Kolkata').format("DD-MM-YYYY hh:mm A");
    return result
  }
  return (
    <Layout sidebar={true}>
      <div className="page-heading backbtn">
        <h3>
          <Link to="/appreviews-ios" className='btn btn-transparent btn-icon me-2'>
            <i className='bx bx-chevron-left' ></i>
          </Link>
          App Reviews iOS View
        </h3>
      </div>
      <div className='page-content'>
        <Card className="radiustop-0">
          <Card.Body>
            <Row>
              {/* <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>App Name</p><span>Auto Clicker	</span>
                </div>
              </Col> */}
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Package Name</p><span>{iosReview?.package_name ? iosReview?.package_name : "-"}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Version Code</p><span>{iosReview?.version_code ? iosReview?.version_code : "-"}</span>
                </div>
              </Col>
              <Col md={12}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Review</p><span>{iosReview?.review ? iosReview?.review : "-"}</span>
                </div>
              </Col>
              <Col md={12}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Subscription Review</p><span>{iosReview?.subscription_review ? iosReview?.subscription_review : "-"}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Use Of App</p><span>{iosReview?.use_of_app ? iosReview?.use_of_app : "-"}</span>
                </div>
              </Col>

              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Created At</p><span>{iosReview?.created_at ? convertTimeZone(iosReview?.created_at)  : "-"}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Updated At</p><span>{iosReview?.updated_at ? convertTimeZone(iosReview?.updated_at) : "-"}</span>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  )
}

export default AppReviewsIOSViews