import React from "react";

const PageNotFound=()=>{
    return(
    <>
        <div className="error">
            <div class="no-found-page">
                <img src="../../not-found/404.svg" alt="404"/>
                <h1>Oops, Page not found!</h1>
                <p>You can go back home.</p>
                <a class="btn btn-primary" href="/">Back To Home</a>
            </div>
        </div>
        </>
    )
}

export default PageNotFound;