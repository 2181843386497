import { API } from "../../App";
import Cookies from "js-cookie";


export const getAndroidReview = async (payload) => {
      const token = Cookies.get("fmljwt")
      try {
          const result = await API.post("/android-review-list", payload, { headers: { authorization: `${token}` } })
          return result
      } catch (error) {
          console.log(error)
      }
  
  };
export const androidAppName = async (payload) => {
      const token = Cookies.get("fmljwt")
      try {
          const result = await API.post("/app-dropdown-android", {}, { headers: { authorization: `${token}` } })
          return result
      } catch (error) {
          console.log(error)
      }
  
  };
export const androidVersionCode = async (payload) => {
      const token = Cookies.get("fmljwt")
      try {
          const result = await API.post("/version-codes-android", payload, { headers: { authorization: `${token}` } })
          return result
      } catch (error) {
          console.log(error)
      }
  
  };
  export const deleteReview = async (payload) => {
    const token = Cookies.get("fmljwt")
    try {
        const result = await API.post("/android-review-delete", payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
