import { createContext, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'rsuite/dist/rsuite.css';
import './style/bootstrap.css';
import 'boxicons/css/boxicons.css';
import 'bootstrap-daterangepicker/daterangepicker.css'
import './style/App.css';
import './style/utilities.css';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { env } from './env'
import { ToastContainer } from 'react-toastify';
import PrivetRoutes from './component/PrivetRoutes';
import Protected from './component/Protected';

import Home from './pages/Home';
import Login from './pages/Login';
import PageNotFound from './pages/PageNotFound';

import Application from './pages/Application/Application';
import ApplicationAdd from './pages/Application/ApplicationAdd';
import ApplicationView from './pages/Application/ApplicationView';

import AppReviewsAd from './pages/AppReviewsAd/AppReviewsAd';
import AppReviewsAdViews from './pages/AppReviewsAd/AppReviewsAdView';
import AppReviewsIOS from './pages/AppReviewsIOS/AppReviewsIOS';
import AppReviewsIOSViews from './pages/AppReviewsIOS/AppReviewsIOSView';
import AppReviewsAnd from './pages/AppReviewsAd/AppReviewsAnd';

export const API = axios.create({ baseURL: process.env.REACT_APP_BASE_URL})

export const AuthContext = createContext()
const App = () => {
    const [classActive, setClassActive] = useState({
        drp1: false,
        drp2: false,
        drp3: false,
    });

    const [theme,setTheme]=useState(localStorage.getItem('theme') ? localStorage.getItem('theme') : "")
    const [isExpanded, setIsExpanded]=useState(localStorage.getItem('sidebar-collapsed'))
    return (
        <>
            <AuthContext.Provider value={{ classActive, setClassActive, theme, setTheme, isExpanded, setIsExpanded }}>
                <BrowserRouter>
                    <Routes>
                        <Route element={<Protected />}>
                            <Route path="/" element={<Login />} />
                        </Route>
                        <Route element={<PrivetRoutes />}>
                            <Route path="/Home" element={<Home />} />
                            <Route path="/application" element={<Application />} />
                            <Route path="/application/add" element={<ApplicationAdd />} />
                            <Route path="/application/edit/:id" element={<ApplicationAdd />} />
                            <Route path="/application/view/:id" element={<ApplicationView />} />

                            <Route path="/appreviews-ad" element={<AppReviewsAnd />} />
                            <Route path="/appreviews-ad/view/:id" element={<AppReviewsAdViews />} />

                            <Route path="/appreviews-ios" element={<AppReviewsIOS />} />
                            <Route path="/appreviews-ios/view/:id" element={<AppReviewsIOSViews />} />
                        </Route>
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </BrowserRouter>
                <ToastContainer position='bottom-right' autoClose={500} />
            </AuthContext.Provider>
        </>
    )
}

export default App;
