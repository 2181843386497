import Pagination from "rc-pagination";
import React, { useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import { SelectPicker } from "rsuite";
import Fancybox from "../../component/FancyBox";
import Layout from '../../layout/Layout';
import { deleteReview, androidAppName, androidVersionCode, getAndroidReview } from "./service";
import moment from 'moment-timezone';
import Swal from "sweetalert2";
import $ from "jquery";
import { toast } from "react-toastify";

const limits = ['25', "50", '100'].map(
  item => ({ label: item, value: item })
);
const AppReviewsAd = () => {

  const [isLoading, setIsLoading] = useState(true)
  const [androidReviewList, setAndroidReviewList] = useState(true)
  const [limit, setLimit] = useState("25");
  const [current, setCurrent] = useState(1);
  const [dropdown, setDropdown] = useState({ appName: [], version: [] });
  const [selectFilter, setSelectFilter] = useState({ appName: "", version: "" })


  /**get Application name  */
  const getAppName = async () => {
    try {
      const response = await androidAppName()
      if (response?.data?.status) {
        const allOption = [{ value: "", label: "All Applications" }];
        const transformedData = response?.data?.data?.length > 0 ? response?.data?.data?.map(item => ({
          value: item.packageName,
          label: item.applicationName
        })) : [];
        setDropdown(prevState => ({
          ...prevState,
          appName: [...allOption, ...transformedData],
        }))
      };

    }
    catch (error) {
      console.log(error)
    } finally {
      // setIsLoading(false)
    }
  };
  /**get version code  */
  const getVersionCode = async (packageName) => {
    try {
      const formData = new FormData()
      formData?.append("package_name", packageName)
      const response = await androidVersionCode(formData)
      if (response?.data?.status) {
        const allOption = [{ value: "", label: "All Version Code" }];
        const transformedData = response?.data?.data?.length > 0 ? response?.data?.data?.map(item => ({
          value: item.version_codes,
          label: item.version_codes
        })) : [];
        setDropdown(prevState => ({
          ...prevState,
          version: [...allOption, ...transformedData]
        }))
      }

    }
    catch (error) {
      console.log(error)
    } finally {
      // setIsLoading(false)
    }
  };
  /**get Application list */
  const getAndroidsReview = async () => {
    try {
      setIsLoading(true)
      const formData = new FormData()
      formData?.append("limit", limit)
      formData?.append("page", current)
      selectFilter?.appName && formData?.append("package_name", selectFilter?.appName)
      selectFilter?.version && formData?.append("version_code", selectFilter?.version)
      const result = await getAndroidReview(formData)
      if (result?.data?.status) {
        setAndroidReviewList(result?.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  };


  const onChange = page => {
    setCurrent(page);
  };

  const handleLimitChange = (e) => {
    setCurrent(1);
    setLimit(e)
  }

  // let count = 10
  let swalCountdownInterval
  const handleDelete = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: '../../icon/alert.svg',
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        // didOpen: () => {
        //   $(".swal2-confirm").attr('disabled', true);
        //   swalCountdownInterval = setInterval(function () {
        //     count--
        //     if (count < 1) {
        //       $(".counter").text(`OK`)
        //       $(".swal2-confirm").attr('disabled', false);
        //       clearInterval(swalCountdownInterval)
        //     } else {
        //       $(".counter").text(`OK (${count})`)
        //     }
        //   }, 1000);
        // }
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true)
          const formData = new FormData
          formData?.append("id", id)
          const deletedata = await deleteReview(formData)
          if (deletedata?.data?.status) {
            toast.success(deletedata?.data?.message)
          }
          getAndroidsReview();
          setCurrent(1);
          // } else {
          //   count = 10
          //   clearInterval(swalCountdownInterval)
        }
      });
  };


  useEffect(() => {
    getAppName()
  }, [])
  useEffect(() => {
    selectFilter?.appName && getVersionCode(selectFilter?.appName)
  }, [selectFilter?.appName])
  useEffect(() => {
    getAndroidsReview()
  }, [current, limit, selectFilter])


  const convertTimeZone = (date) => {
    const formateDate = moment(date).format()
    const result = moment(formateDate).tz('Asia/Kolkata').format("DD-MM-YYYY hh:mm A");
    return result
  }
  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1">App Reviews AD</h3>
        <div className="page-heading-right">
          <SelectPicker
            data={dropdown?.appName}
            onChange={(e) => {
              setSelectFilter(prevState => ({
                ...prevState,
                appName: e,
                version: ""
              }))
              getVersionCode(e);
              setDropdown(prevState => ({
                ...prevState,
                version: []
              }))
            }
            }
            cleanable={false}
            className="wv-250 my-1 ms-3"
            placeholder="Select App Name"
            placement="bottomEnd"
          />
          <SelectPicker
            data={dropdown?.version}
            onChange={(e) => {
              setSelectFilter(prevState => ({
                ...prevState,
                version: e
              }))
            }}
            cleanable={false}
            className="wv-150 my-1 ms-3"
            placeholder="Select Version"
            placement="bottomEnd"
          />
          <SelectPicker
            cleanable={false}
            data={limits}
            value={limit}
            onChange={handleLimitChange}
            searchable={false}
            className="wv-100 my-1 ms-3"
            placeholder="Select Record"
          />
        </div>
      </div>
      <div className="page-content">
        <Card className="radiustop-0">
          <Card.Body>
            {isLoading && <div className="loader table-loader"></div>}
            <Table className="table-bg-primary" striped bordered responsive>
              <thead>
                <tr>
                  <th width="5%" className="text-center">No</th>
                  <th width="35%">Review</th>
                  <th width="5%" className="text-center">Ratings</th>
                  <th width="5%" className="text-center">Version</th>
                  <th width="15%" className="text-center">Image</th>
                  <th width="15%">Email / Number</th>
                  <th width="9%">Date</th>
                  <th width="11%" className='text-center'>Action</th>
                </tr>
              </thead>
              <tbody>
                {androidReviewList?.data?.length > 0 ? androidReviewList?.data?.map((item, index) => {
                  return (<tr key={index}>
                    <td className='text-center'>{(current === 1) ? index + 1 : current * limit + index + 1 - limit}</td>
                    <td>{item?.review ? item?.review : "-"}</td>
                    <td className="text-center">{item?.ratings ? item?.ratings : "-"}</td>
                    <td className="text-center">{item?.version_code ? item?.version_code : ""}</td>
                    <td className="text-center">
                      <Fancybox>
                        <a href={item?.image} data-fancybox="gallery">
                          <img src={item?.image ? item?.image : "/avatar/1.jpg"}
                            onError={(e) => {
                              e.target.src = "/avatar/1.jpg";
                            }}
                            className="hv-40 rounded-3" alt="" />
                        </a>
                      </Fancybox>
                    </td>
                    <td>{item?.contact_information ? item?.contact_information : "-"}</td>
                    <td>{item?.created_at ? convertTimeZone(item?.created_at) : "-"}</td>
                    <td>
                      <div className='d-flex align-items-center justify-content-center'>
                        <Link to={`/appreviews-ad/view/${item?.id}`} className="btn btn-icon btn-transparent btn-primary">
                          <i className='bx bx-show'></i>
                        </Link>
                        <span className='border-start py-2 mx-2'></span>
                        <Button variant="danger" size="sm" onClick={() => handleDelete(item?.id)} className="btn-icon btn-transparent">
                          <i className='bx bx-trash' ></i>
                        </Button>
                      </div>
                    </td>
                  </tr>)
                }) :
                  <tr>
                    <td colSpan="100%" className="p-0">
                      <div className='no-found'>
                        <img src="../../not-found/app.svg" />
                        <p>No Found App Reviews</p>
                      </div>
                    </td>
                  </tr>
                }
              </tbody>

            </Table>
            {androidReviewList?.total > limit && <div className="pagination-custom">
              <Pagination
                className="pagination-data"
                onChange={onChange}
                current={current}
                total={androidReviewList?.total}
                pageSize={limit}
                showSizeChanger={false}
                showTitle={false}
              />
            </div>}
          </Card.Body>
        </Card>
      </div>
    </Layout>
  )
}

export default AppReviewsAd